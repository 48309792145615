<template>
    <div class="body">
        <!-- banner区域 Start -->
        <div class="banner">
            <m-top-bar></m-top-bar>
        </div>
        <!-- banner区域 End -->

        <!-- switchBar区域 Start -->
        <div class="switchBar">
            <div class="switchBox">关于宁讯</div>
        </div>
        <div class="line"></div>
        <!-- switchBar区域 End -->

        <!-- container区域 Start -->
        <div class="container">
            <div class="containerBox">
                <div class="intro">
                    公司简介：<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;宁讯信息科技有限公司成立于2012年12月，核心团队由国内资深行业高等级软硬件研发与测试人员和技术营销型复合销售人员构成。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司主要产品定位物联网传输层无线传输产品为主，包括工业物联网安全通讯网关、智能终端相关增值解决方案。<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;专注致力于工业级无线网络智能产品及解决方案的提供商，公司主要产品有工业无线传输系列、无线智能保管柜系列以及智能维护平台中间件系列，其中无线产品包括多功能无线控制器、无线移动切换基站、无线智能终端、一线通智能无线覆盖基站及RFID读写单元。公司产品市场主要定位应用在数字石化油田、数字工厂、智能电力、巡检机器人、AGV机器人、无人驾驶、新能源等领域。<br>
                </div>
                <div class="brand">
                    <img src="../../../assets/mobile/common/pic/logoYB.png" alt="" class="logo"><br>
                    品牌诠释：<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中文 宁讯，LINK英文意为连接，”KOM” 德语为“COM”通讯、通信同义表示，LINKOM，即为连接通信；<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;品牌定义产品要做工业等级高品质的通信相关产品及系统平台相关解决方案。
                </div>
                <div class="patent">
                    拥有专利软著：<br>
                    <img src="../../../assets/mobile/img/about/logo.png" alt="" class="img">
                    <img src="../../../assets/mobile/img/about/certification.jpg" alt="" class="img"><br>
                    <img src="../../../assets/mobile/img/about/soft.png" alt="" class="img">
                    <img src="../../../assets/mobile/img/about/patent.png" alt="" class="img"><br>
                    <img src="../../../assets/mobile/img/about/approve.png" alt="" class="img">
                </div>
            </div>
        </div>
        <!-- container区域 End -->

        <!-- baseBar区域 Start -->
        <m-base-bar></m-base-bar>
        <!-- baseBar区域 End -->
    </div>
</template>

<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
    name: 'About',
    components: {
        mTopBar,
        mBaseBar,
    }
}
</script>

<style scoped>
.body {
    background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
    width: 100%;
    min-height: 2050px;
    background: url(../../../assets/mobile/img/about/banner.jpg) center no-repeat;
    background-size: 210%;
}

/* switchBar区域 */
.switchBar {
    padding-top: 50px;
    width: 100%;
    height: 300px;
    background-color: #ffffff;
}

.switchBar .switchBox {
    margin: 0 auto;
    text-align: center;
    width: 700px;
    height: 200px;
    font-size: 82px;
    line-height: 200px;
    color: #ffffff;
    border-radius: 30px;
    background-color: #c3a35d;
}

.line {
    margin: 0 auto;
    width: 90%;
    border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
    padding: 50px;
    width: 100%;
    min-height: 500px;
}

.container .containerBox {
    padding: 100px;
    width: 100%;
    min-height: 300px;
    font-size: 70px;
    background-color: #ffffff;
}

.container .containerBox .intro,.brand {
    margin-bottom: 200px;
    text-align: justify;
}

.container .containerBox .brand .logo {
    height: 200px;
    margin-bottom: 50px;
}

.container .containerBox .patent .img {
    margin-right: 100px;
    margin-top: 100px;
    width: 710px;
}
</style>
